import React from 'react'
import Helmet from 'react-helmet'
import config from "../../../data/config.js";

export default function createJSONLDTag(
  {
  isRoot,
  isPost,
  title,       // 記事タイトル（記事ページの時のみ指定）
  description, // 記事概要（記事ページの時のみ指定）
  postUrl,     // 記事URL（記事ページの時のみ指定)
  postDate,    // 記事更新日（記事ページの時のみ指定）
  categoryName,
  categorySlug,
}
) {

  // ブログ管理者（自分）
  const author = [
    {
      '@type': 'Person',
      name: config.author,
      description: config.blogAuthorDescription,
      image: {
        '@type': 'ImageObject',
        url: config.blogAuthorAvatarUrl,
        width: 60,
        height: 60
      },
      'url': config.siteUrl,
      "sameAs": [
        config.blogAuthorFacebookUrl,
        config.blogAuthorTwitterUrl,
      ]
    },
    {
      '@type': 'thing',
      name: config.author,
      sameas: config.siteTitle,
      url: config.siteUrl,
      image: {
        '@type': 'ImageObject',
        url: config.blogImageUrl,
        width: 60,
        height: 60
      }
    }
  ];

  // ブログ発行者（自分）
  const publisher = {
    '@type': 'Organization',
    name: config.author,
    description: config.blogAuthorDescription,
    logo: {
      '@type': 'ImageObject',
      url: config.blogAuthorAvatarUrl,
      width: 60,
      height: 60
    }
  }

  // JSON+LDの設定
  const jsonLdConfigs = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      inLanguage: 'ja',
      url: config.siteUrl + "/"+ postUrl,
      name: title,
      alternateName: config.siteTitle,
      image: config.blogImageUrl,
      description: config.blogAuthorDescription,
      author,
      publisher,
      potentialAction: {
        '@type': "SearchAction",
        target: `${config.siteUrl}/search?q={q}`,
        'query-input': 'required name=q'
      }
    }
  ];

  if (isPost) {
    jsonLdConfigs.push({
        '@context': 'http://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            item: {
              '@id': config.siteUrl,
              name: config.siteTitle,
              image: config.blogImageUrl,
            },
          },
          {
            '@type': 'ListItem',
            position: 2,
            item: {
              '@id': config.siteUrl+"/"+categorySlug,
              name: categoryName,
              image: config.blogImageUrl,
            },
          },
          {
            '@type': 'ListItem',
            position: 3,
            item: {
              '@id': config.siteUrl+"/"+categorySlug+"/"+postUrl,
              name: title,
              image: config.blogImageUrl,
            },
          },
        ],
    })

    jsonLdConfigs.push({
        '@context': 'http://schema.org',
        '@type': 'BlogPosting',
        url: config.postUrl,
        name: title,
        alternateName: config.siteTitle,
        headline: title,
        image: {
          '@type': 'ImageObject',
          url: config.blogImageUrl,
        },
        description,
        datePublished: postDate,
        dateModified: postDate,
        mainEntityOfPage: {
          '@type': 'WebPage',
          '@id': config.siteUrl
        },
        author,
        publisher,
      });
    };
  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(jsonLdConfigs)}</script>
    </Helmet>
  )
}