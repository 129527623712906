import React, { Component } from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  LineShareButton,
  LineIcon
} from "react-share";
import urljoin from "url-join";
import config from "../../../data/config";
import "./SocialLinks.css";

class Share extends Component {
  render() {
    const { postNode, postPath, mobile } = this.props;
    const post = postNode.frontmatter;
    const url = urljoin(config.siteUrl, postPath);
    const iconSize = mobile ? 36 : 48;

    return (
      <div className="social-links">
        <TwitterShareButton url={url} title={post.title}>
          <TwitterIcon round size={iconSize} />
        </TwitterShareButton>
        <FacebookShareButton url={url} quote={postNode.excerpt}>
          <FacebookIcon round size={iconSize} />
        </FacebookShareButton>
        <LineShareButton url={url} quote={postNode.excerpt}>
          <LineIcon round size={iconSize} />
        </LineShareButton>
      </div>
    );
  }
}

export default Share;
