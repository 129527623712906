import React from 'react'
import {Link} from "gatsby"
import JsonLD from "./jsonLD.js"
import styled from 'styled-components'
import Image from '../../components/image'

const Wrap = styled.div`
background-Color:#F2EFB6;
padding:4px;
width:100%;
border-radius:8px;
`
const ItemList = styled.ol`
  display: inline;
`
const Item = styled.li`
  display: inline;
  margin: 0px;
  padding-left: 0;
    text-decoration: none;
`

const ArrowIcon = styled.div`
  height: 16px;
  width: 16px;
  display: inline-block;
  margin: 0px 8px
`

const StyledLink = styled(Link)`
color: #112d4e;
text-decoration: none;
`

class Breadcrumbs extends React.Component {
  render() {
  const post= this.props.post
  return (
<Wrap>
<JsonLD
title= {post.frontmatter.title}
description ={post.excerpt}
postUrl = {post.fields.slug}
postDate = {post.frontmatter.date}
categoryName = {post.frontmatter.categoryName}
categorySlug =  {post.frontmatter.categorySlug}
isPost = "true"
/>

  <ItemList>
    <Item>
      <StyledLink to="/">Home</StyledLink>
    </Item>
      <ArrowIcon><Image filename="arrow.png"></Image></ArrowIcon>
    <Item>
      <StyledLink to={"/" + post.frontmatter.categorySlug +"/"}
      >
      {post.frontmatter.categoryName}
      </StyledLink>
    </Item>
      <ArrowIcon><Image filename="arrow.png"></Image></ArrowIcon>
    <Item>
      {post.frontmatter.title}
    </Item>
  </ItemList>
  </Wrap>
)}}
export default Breadcrumbs
