import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import rehypeReact from "rehype-react"
import Share from '../components/Share';
import Breadcrumbs from '../components/Breadcrumbs'

const renderAst = new rehypeReact({
  createElement: React.createElement,
}).Compiler

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext
    const { slug } = this.props.pageContext;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={post.frontmatter.title} description={post.excerpt} />
        <h1>{post.frontmatter.title}</h1>
        <p>
          {post.frontmatter.date}
        </p>
        <Breadcrumbs post={post}/>
        <div>{renderAst(post.htmlAst)}</div>
        <hr/>
          <Share postPath={slug} postNode={post} />
      <div className="prevnext">
        <ul>
          {previous && (
          <li>
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
          </li>
          )}
          {next && (
          <li>
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
          </li>
            )}
        </ul>
      </div>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      htmlAst
      fields{
        slug
      }
      frontmatter {
        title
        categoryName
        categorySlug
        date(formatString: "MM DD, YYYY")
      }
    }
  }
`
